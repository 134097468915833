import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const CSE = () => {
  const products = Array.from({ length: 12 }, (_, index) => ({
    imageSrc: `/product4/${index + 1}.png`, // Assuming images are named 1.png, 2.png, ..., 44.png
  }));

  return (
    <div className="cms">
      <div className="product-cart-container-3">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} 
            width="20%"
          />
        ))}
      </div>
      <p>
      &nbsp;The success of a meeting is dependent on excellent sound. Simultaneous
        interpretation systems have to have microphones, so nothing is simpler
        than adding a loudspeaker system to them, then in addition to having
        clear, natural speech for the simultaneous interpreters the same quality
        is heard in the meeting room<br/>
        &nbsp;<b>aa Conference Services</b>  offers audio
        equipment rentals and sound system rental packages for meetings and
        events of all shapes and sizes – large, medium, or small. Whether you
        need an audio solution for a panel of speakers, a general session
        ballroom presentation, or an outdoor event, we have all of the equipment
        you'll need and any of our audio rental solutions can be custom-tailored
        for your event.  For speaking events, choose a PA system rental which
        includes speakers, cabling and your choice of microphone. For amplifying
        an audio source such as a laptop or iPod, choose from our sound system
        rentals which include speakers and mixers to connect directly into your
        audio source. Not sure which system is right for your meeting or event
        or looking for a custom audio solution? Call or email us for additional
        assistance.<br/> We can offer you our Portable Interpreting Equipment -
        economical and perfect solution for efficient interpreting in smaller
        groups of international delegates, or when a small percentage of the
        total number of participants requires simultaneous interpreting.<br/><br/> We
        can also offer all the equipment and support services needed for a
        successful press conference or media announcement: press box, podium
        microphone, sound system and onsite support services throughout the
        event. Lapel and ear hook microphones which allow the wearer to move
        around with them without being lost to the interpreter.
      </p>
    </div>
  );
};

export default CSE;
