import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const SIB = () => {
  const products = Array.from({ length: 2 }, (_, index) => {
    
    
    if (index === 1) {
      return {
        imageSrc: `/product5/${index + 1}.png`,
        width: "30%" ,
      }
    }
    return  {
      imageSrc: `/product5/${index + 1}.png`, 
    }
  });

  return (
    <div className="cms">
      <div className="product-cart-container-4">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} 
            width={product.width || "45%"} 
          />
        ))}
      </div>
      <p>
      &nbsp;Our translation booths are ideal for simultaneous interpretation, meetings and conferences and can provide the perfect visual and two-way audio communication between the interpreters and the participants!,<br /><br />

Features of our full-sized translation booths:<br /><br />

<b>•</b>&nbsp;&nbsp;Conform to ISO-4043<br />
<b>•</b>&nbsp;&nbsp;Size: 160 cm width x 156 cm depth x 203 cm height<br />
<b>•</b>&nbsp;&nbsp;Can accommodate 2 interpreters comfortably<br />
<b>•</b>&nbsp;&nbsp;Include a ventilation system<br />
<b>•</b>&nbsp;&nbsp;Light weight<br />
<b>•</b>&nbsp;&nbsp;Easy to assemble<br />
<b>•</b>&nbsp;&nbsp;Include shipping cases<br /><br />

   Tabletop Booths: Designed to create the best acoustic environment for the distinction of various languages spoken simultaneously, our mobile booth provides the perfect visual and two-way audio communication between the interpreters and the participants in various meetings and conferences.<br />
The portable two-person simultaneous interpretation booth is easy to assemble and ideal for workshops and small meetings where space is limited.<br /><br />

Size: 120 cm width X 61 cm depth X 61 cm height
      </p>
    </div>
  );
};

export default SIB;
