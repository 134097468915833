import React, { useState } from 'react';
import './TagInfoPanel.css'; // Import the CSS file
import ProductCartD from '../ProductsCard/ProductCartD';
import MultilingualInterpretationSystem from './pages/MultilingualInterpretationSystem';
import CMS from './pages/CMS';
import CSE from './pages/CSE';
import SIB from './pages/SIB';
import VCS from './pages/VCS';
import LVW from './pages/LVW';
import HLEV from './pages/HLEV';
import VPS from './pages/VPS';
import CNT from './pages/CNT';
import Accessories from './pages/Accessories';

// Define an array of descriptions
const descriptions = [
  "Williams Sound, LLC,United States",
  "Haitian Electronic Technology Co., Ltd., China",
  "Foshan Singden Technology Co.,Ltd., China",
  "Conference Systems (CSI), United States",
  "Shure Incorporated, United States",
  "Electro-Voice, United States",
  "AKG, Austria",
  "König & Meyer GmbH, Germany ",
  "Mackie, United States",
  "Riole, Brazil",
  "Peavey Electronics Corporation, United States",
  "Multi-Caisses Inc., Canada",
  "Beyerdynamic GmbH & Co. KG, Germany",
  "Audio Press Box, Slovak Republic (EU)",
  "Dynacord, Germany",
  "Harman Pro North America,United States",
  "Shenzhen Dicolor Optoelectronics Co., Ltd., China",
  "Xi’an NovaStar Tech Co., Ltd., China",
  "Sgaier Co., Ltd., China",
  "Logitech Inc., United States",
  "Legrand LLC, The Netherlands",
  "Proel S.p.a., Italy",
  "IAG UK, United Kingdom",
  "Lexicon LLC, United States ",
  "Blackmagic Design Pty Ltd., Australia ",
  "Shenzhen Tenveo Video Technology Co., Ltd, China ",
  "Neewer, Inc., United States ",
  "Dell, Incorporated, United States ",
  "Samsung Group, South Korea ",
  "Corsair Gaming, Inc., United States ",
  "Micro-Star International Co., Ltd, Taiwan ",
  "Thermaltake Technology Co., Ltd, China ",
  "Lenovo Group Limited, China ",
  "Shenzhen Dosunn Technology Co., Ltd, China ",
  "Kingston Technology Corporation, United States ",
  "Hewlett-Packard Company, United States ",
  "Seiko Epson Corporation, Japan ",
  " Forward Video Technology Co., Ltd., China",
  "ASUSTek Computer Inc., Taiwan ",
  "Nanjing Techlets International Trade Co., Ltd., China ",
  "Roland Corporation, Japan  ",
  "AVIXA Inc, United States ",
  "Enping Kebit Electronics Factory, China ",
  "the box by Thomann Music,  Germany ",
   "ViewSonic Corporation, United States ",
  "Sennheiser electronic SE & Co. KG,  Germany "
];

const products = Array.from({ length: 46 }, (_, index) => ({
  imageSrc: `/product/${index + 1}.png`, // Assuming images are named 1.png, 2.png, ..., 44.png
  description: descriptions[index], // Use the description from the array
}));

const TagInfoPanel = ({ selectedTag }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  // const renderBrowserSupportTable = () => (
  //   <table>
  //     <thead>
  //       <tr>
  //         <th>Element</th>
  //         <th>Chrome</th>
  //         <th>Firefox</th>
  //         <th>Opera</th>
  //         <th>Safari</th>
  //         <th>Edge</th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       <tr>
  //         <td>{selectedTag}</td>
  //         <td>Yes</td>
  //         <td>Yes</td>
  //         <td>Yes</td>
  //         <td>Yes</td>
  //         <td>Yes</td>
  //       </tr>
  //     </tbody>
  //   </table>
  // );

  const getTagInfo = (tag) => {
    switch (tag) {
      case 'Multilingual Interpretation System':
        return (
          <div>
           <MultilingualInterpretationSystem />
          </div>
        );

      case 'Conference Microphone System':
        return (
          <div>
            <CMS />
          </div>
        );

      case 'Conference Sound Equipment':
        return (
          <div>
            <CSE />
          </div>
        );

      case 'Simultaneous Interpretation Booth':
        return (
          <div>
            <SIB />
          </div>
        );
      case 'Video Conference System':
        return (
          <div>
            <VCS />
          </div>
        );
      case 'LED Video Wall':
        return (
          <div>
            <LVW />
          </div>
        );
      case 'Hybrid Events / Live Streaming / Event Recording':
        return (
          <div>
            <HLEV />
          </div>
        );
      case 'Video Presentation Systems':
        return (
          <div>
            <VPS />
          </div>
        );
      case 'Computers / Notebooks / Tablets':
        return (
          <div>
            <CNT />
          </div>
        );
      case 'Accessories':
        return (
          <div>
            <Accessories />
          </div>
        );
      default:
        return (
          <div className="default">
                      <p>
                      &nbsp; We offer you a full range of simultaneous interpretation and conference equipment for rent. From portable and stationary transmitters to wireless FM receivers, push-to-talk microphones, and interpreters  booths, at <b>aa Conference Services</b>, we have it all at the best price and with unparalleled customer service and support.<br/>
                      &nbsp; We will guide you to the correct simultaneous interpretation equipment and package for your conference with an understanding of your budgetary needs, making you feel at ease and comfortable in the world of different languages.<br/>
              Please feel free to browse through our different translation equipment and conference systems options or contact us for further assistance and to get a customized quote.<br/>
              We provide state-of-the-art equipment and make sure that every system is assembled strategically for superb sound and transmission. Part of our strategy is to provide backups on key equipment at all times.<br/>
              We can rent our equipment per day or with a complete simultaneous interpretation package. Rental services include transportation, setup, and permanent technical assistance, including distribution and collection of receivers to participants during conferences.
            </p>

            <div className="product-cart-container">
              {products.map((product) => (
                <ProductCartD
                  key={product.imageSrc}
                  imageSrc={product.imageSrc} // Image source
                  description={product.description} // Unique description for each product
                />
              ))}
            </div>
          </div>
        );
    }
  };

  return <div>{getTagInfo(selectedTag)}</div>;
};

export default TagInfoPanel;
