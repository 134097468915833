import React, { useState, useEffect, useMemo } from 'react';
import { Link, Element } from 'react-scroll';
import TagInfoPanel from '../TagInfoPanel';
import './Menu.css';
import Modal from '../Model/Modal';
import { NavLink, useNavigate } from 'react-router-dom';
import Loading from '../../Loading/loading';

const MenuPage = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(null); // Add this state
  const [sections, setSections] = useState({
    alphabet: false,
    category: false,
    tags: true,
    tutorial: false,
  });
  const [selectedTag, setSelectedTag] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const [hoveredTag, setHoveredTag] = useState(null);
  const [showAllTags, setShowAllTags] = useState(true);
  const [loading, setLoading] = useState(true);

  const MenuTags = useMemo(() => [
    'Multilingual Interpretation System',
    'Conference Microphone System',
    'Conference Sound Equipment',
    'Simultaneous Interpretation Booth',
    'Video Conference System',
    'LED Video Wall',
    'Hybrid Events / Live Streaming / Event Recording',
    'Video Presentation Systems',
    'Computers / Notebooks / Tablets',
    'Accessories',
  ], []);

  const filteredMenuTags = useMemo(() =>
    MenuTags.filter(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())),
    [searchTerm, MenuTags]
  );

  const openModal = (imageUrl) => setModalImageUrl(imageUrl);
  const closeModal = () => setModalImageUrl(null);

  const toggleSection = (section) => {
    setSections(prevSections => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const toggleTags = () => {
    setShowAllTags(!showAllTags);
  };

  const showTagInfoAndNavigate = (tag) => {
    setSelectedTag(tag);
    navigate(`/equipment_rental?tag=${encodeURIComponent(tag)}`);
  };

  const resetToDefault = () => {
    setSelectedTag(null);
    setSearchTerm('');
    setShowAllTags(true);
    setSections({
      alphabet: false,
      category: false,
      tags: true,
      tutorial: false,
    });
    navigate('/equipment_rental');
  };

  const handleButtonClick = () => {
    setSelectedButton('services-profile'); // Set this button as selected
    resetToDefault();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    if (selectedTag) {
      const selectedTagElement = document.querySelector(`.tag-item.selected`);
      if (selectedTagElement) {
        selectedTagElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedTag]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='ER-section'>
      <div className="Interpretation-banner-er">
        <h1 className="Interpretation-banner-text-er">EQUIPMENT RENTAL</h1>
      </div>

      <div className="container">
        <div className='section-containerr'>
          {sections.tags && (
            <div className="section-content">
              <ul>
                <a
                  href="/equipment_rental"
                  className={`menu-button ${selectedButton === 'services-profile' ? 'selected' : ''}`}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent full page refresh
                    handleButtonClick(); // Set button to active and reset view
                  }}
                >
                  <h4>Services and Profile</h4>
                </a>

                {filteredMenuTags.slice(0, showAllTags ? filteredMenuTags.length : 3).map((tag, index) => (
                  <li
                    key={index}
                    id={`tag-${index}`}
                    onClick={() => showTagInfoAndNavigate(tag)}
                    onMouseEnter={() => setHoveredTag(tag)}
                    onMouseLeave={() => setHoveredTag(null)}
                    role="button"
                    tabIndex="0"
                    className={`tag-item ${selectedTag === tag ? 'selected' : ''} ${hoveredTag === tag ? 'hovered' : ''}`}
                  >
                    <Link
                      to="tag-info"
                      smooth={true}
                      duration={500}
                      onClick={() => setSelectedTag(tag)}
                    >
                      {tag}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <Element name="tag-info" className="side-panel">
          <TagInfoPanel selectedTag={selectedTag} />
        </Element>
      </div>

      <div>
        <div className="tags-img">
          {modalImageUrl && <Modal imageUrl={modalImageUrl} closeModal={closeModal} />}
        </div>
      </div>
    </div>
  );
};

export default MenuPage;
