import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const HLEV = () => {

  const products = Array.from({ length: 8 }, (_, index) => ({
    imageSrc: `/product8/${index + 1}.png`, // Assuming images are named 1.png, 2.png, ..., 44.png
  }));

  return (
    <div className="mis">
      <div className="product-cart-container-2">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} 
            width="20%"
          />
        ))}
      </div>
      <p>
      &nbsp;&nbsp;Hybrid event is a mixture of virtual and live elements. That is the best way to send your message to larger audience, which virtually has no limit. We provide full support for hybrid events  from all the equipment for live event to live stream for virtual audience.<br />
      &nbsp;&nbsp;<b>aa Conference Services</b> offers customers the modern Full HD 1080P resolution video conferencing solutions based on the ZOOM platform. We have chosen ZOOM as the basis on which to build the most suitable online event solution for each customer due to several advantages of this platform - simultaneous translation in several languages, voting, chat, whiteboard, virtual rooms for ZOOM conference group work.<br /><br />

      &nbsp;&nbsp; Event live streaming services will expand your audience reach and brand awareness. You can patch your feed to different online platforms, including YouTube, Zoom, Microsoft Teams, and social media platforms!<br />
Select a package that suits your needs, or let our technical specialist customize your setup. We will provide all your needed equipment, complete with installation, operation, breakdown, and analytics.<br />
aa Conference Services elevate your events with live event video production. Our turnkey production service is your one-stop solution for events of any size. We will collaborate with your team to ensure a smooth-sailing streaming experience.<br />

&nbsp;&nbsp;Event recordings allow you to create a video that you can continue sharing and using long after your event is over. These videos also provide your organization with a way to deliver your message to attendees who couldn t make it to the on-site event.<br />
From offering highlight reels to functioning as a marketing tool, your event recording will speak to both current and future attendees. You can count on aa Conference Services to help you take advantage of these benefits as you prepare for your next event.<br />

&nbsp;&nbsp; We offer spotlights and remotely adjustable LED panels for your venue lighting solutions. 
        success of your conference.
      </p>
    </div>
  );
};

export default HLEV;
