import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const Accessories = () => {
  const products = Array.from({ length: 10 }, (_, index) => {
    let width = "30%";

    if (index === 0 ) {
      width = "50%";
      
    } 
    else if ( index === 3 ) {
      width = "45%";
    }
    else if (index === 1 || index === 2 ) {
      width = "15%";
    } else if ( index === 4 || index === 5) {
      width = "18%";
    } else if (index === 6 || index === 7 || index === 8  || index === 9 ) {
      width = "19.2%";
    }

    return {
      imageSrc: `/product12/${index + 1}.png`,
      width,
    };
  });

  return (
    <div className="mis">
      <div className="product-cart-container-2">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc}
            width={product.width || "20%"}
          />
        ))}
      </div>
      <p>
        &nbsp;&nbsp;<b>aa Conference Services</b> offers a wide variety of professional accessories to facilitate your event.
        Professional height adjustable Laptop/Projector Stand. Having an adjustable height it is a flexible stand that can be used with both laptops and projectors for presentations.
        <br />
        &nbsp;&nbsp;The arrangement of the meeting room can have a significant impact on the interactions between your panelists and attendees. Designing a stage is not only a technical process, but is one that requires a lot of creativity. We have the perfect mix of conference furniture - a Lectern, Cushioned Chairs with a Coffee Table nearby for the panelists that is needed to create a stage design that will enhance the entire look and feel of your event so that your audience can have an extremely memorable experience.
        <br />
        &nbsp;&nbsp; Four easy-to-carry Speaker Monitor/Light Stands and Supports made of high quality aluminum in a carrying case. Fiberglass-reinforced fittings and a compact clamp with a patented push-button system affords user-friendly height adjustment and ensures stable support for the speakers. The leg construction adjusts by an easy-to-use clamping lever.
        <br />
        &nbsp;&nbsp; The Microphones Boom Stand is a great tool to use at the conference room. There're three adjustable legs, which makes the stand completely flexible and firmly stand at anywhere. With its two different designed microphone clips, it suits for most kinds of microphone.
        <br />
        Tabletop Microphone Stand with gooseneck for easy adjustment.
        <br />
        &nbsp;Mini Table Microphone stand is an interesting tripod design and the height can be adjusted; this microphone stand is perfect for anyone needing to achieve a sound from a desktop or table.
        Wireless Presenter. Intuitive controls and red laser pointer make presentations a snap, even in the darkest auditoriums. Up to 15-meter range and battery indicator help you make ordinary presentations extraordinary. No software needed, just plug the receiver into a USB port and you’re ready to go.
      </p>
    </div>
  );
};

export default Accessories;
