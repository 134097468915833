// components/ProductCart/ProductCart.jsx

import React from 'react';
import './ProductCartD.css';

const ProductCartD = ({ imageSrc, description }) => {
  return (
    <div className="product-cart-d">
      <img src={imageSrc} alt="Product" className="product-image-d" />
      <p className="product-description-d">{description}</p>
    </div>
  );
};

export default ProductCartD;
