import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const CMS = () => {

  const products = Array.from({ length: 11 }, (_, index) => {
   
    if (index === 1) {
      return {
        imageSrc: `/product3/${index + 1}.png`,
        width: "44%" ,
      }
    }
    return  {
      imageSrc: `/product3/${index + 1}.png`, 
    }
  });

  return (
    <div className="cms">
      <div className="product-cart-container-3">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} // Unique description for each product
            width={product.width || "20%"} 
          />
        ))}
      </div>
      <p>
  &nbsp;Interpretation systems must use microphones to enable the interpreters to follow the proceedings. The microphones can also be used for sound systems and recording the event.<br/>
  Our large selection of wired and wireless microphone rentals can be adapted to match the size and setting of your specific event. Whether you're speaking from a podium or need your hands free while presenting, we can provide the perfect solution to fit your needs.<br/>
  If you also need speakers for your event, please follow the link to our Conference Sound Equipment.<br/>
  &nbsp;   The style of conference or meeting will dictate the range of microphones to be installed, which may be any or a combination of the following:<br/><br/>
  
 <b>•</b>&nbsp;&nbsp;Fully digital control and new design, conference system microphones with built-in OLED displays. OLED can display the date and time when standby and support count-up/count-down timing modes when microphone working. Excellent immunity to RF interference from mobile phones and RF devices. Delegate unit with push-to-talk and Chairman unit with priority button;<br/>
  
 <b>•</b>&nbsp;&nbsp; Wireless meeting microphone system that can be perfectly integrated into any meeting rooms, without additional wiring;<br/>
  
 <b>•</b>&nbsp;&nbsp;Professional gooseneck electret condenser microphones designed for use in general sound reinforcement applications for full articulation by providing scalable, best-in-class performance in a wide variety of locations, including podiums and desktops;<br/>
  
 <b>•</b>&nbsp;&nbsp;Question and Answer wireless microphones which are handheld and can be taken to members of an audience when they want to speak, or stand microphones, which are placed around a conference hall for the audience to walk up to when asking questions;<br/>
  
 <b>•</b>&nbsp;&nbsp;Lapel and ear hook microphones that allow the wearer to move around without being lost to the interpreter.
</p>


    </div>
  );
};

export default CMS;
