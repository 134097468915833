// Import necessary dependencies
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import EquipmentRental from "./pages/EquipmentRental/EquipmentRental.jsx"
import ClientsServed from './pages/ClientsServed/ClientsServed';
import ContactUs from './pages/ContactUs/ContactUs';
import InterpretationTranslation from './pages/InterpretationTranslation/IInterT.jsx';

// Create a Layout component
const Layout = ({ children }) => {
  return (
    <div>
      {/* Add a link to scroll to the top */}
      <ScrollLink to="top" smooth={true} duration={500}>
        {/* Scroll to Top */}
      </ScrollLink>

      {/* Header component */}
      <Header />
     
      {/* Page content */}
      {children}
    </div>
  );
};

// Main RoutesConfig component
const RoutesConfig = () => {
  return (
    <Routes>
      {/* Wrap each page component with the Layout */}
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
       <Route path="/" element={<Layout><Home /></Layout>} />
      <Route path="/interpretation_translation" element={<Layout><InterpretationTranslation /></Layout>} />
      <Route path="/equipment_rental" element={<Layout><EquipmentRental /></Layout>} />

      <Route path="/clients_served" element={<Layout><ClientsServed /></Layout>} />
      <Route path="/contact_us" element={<Layout><ContactUs /></Layout>} />
    </Routes>
  );
};

export default RoutesConfig;
