import React from "react";
import "./Home.css";
import { Link } from 'react-router-dom';

const Home = () => {
  const ProductCartHome = ({ imgSrc, altText, description, link }) => {
    return (
      <div className="card">
        <Link to={link}>
          <img src={imgSrc} alt={altText} />
          <p>{description}</p> {/* Added a paragraph for the description */}
        </Link>
      </div>
    );
  };

  return (
    <div className="home-container">
      <div className="home-section">
        <div className="section-1">
          <section className="info-section">
            <h1>WELCOME TO</h1>
            <h2>aa Conference Services</h2>
            <p>Rely on our professionalism & experience</p>
            <div className="search-section" style={{ position: 'relative', display: 'inline-block' }}>
  <input
    type="text"
    placeholder="Search"
    className="search-input"
    style={{ paddingRight: '35px' }} // Adds space for the icon on the right side
  />
  <img
    src="/Union.svg" // Replace with your icon URL or local image path
    alt="Search Icon"
    className="search-icon"
 
  />
</div>

          </section>
        </div>

        <div className="section-2">
          <section className="text-section">
            <p>
              We are freelance language and technical professionals (Private
              Entrepreneurs) providing
              <span className="highlight-text"> interpretation/translation</span>
              , and simultaneous interpretation and audiovisual
              <span className="highlight-background"> equipment rental</span>{" "}
              services.
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;<strong>aa Conference Services</strong> is
              located in Yerevan, Armenia with its vendors and partners in
              various countries. Our team is eager to serve you and respond to
              all your translation/interpretation and equipment rental requests.
              Come and experience the <strong>aa Conference Services</strong>{" "}
              difference!
            </p>
          </section>
        </div>

        <div className="section-3">
          <section className="services-section">
            <h2 className="services-title">
            Services
              </h2>
            <div className="cart-section">
              <div className="cart-1">
              <a href="/interpretation_translation">
  <img src="/picture1.png" alt="services" />
</a>              </div>
        <div className="cart-1">
<a href="/equipment_rental">
          <img src="/picture2.png" alt="services" />
      </a>
        </div>
            </div>
          </section>

          <section className="container-1">
            <div className="our-product-1">
              <div className="our-product-text">
                <div className="our-product-text-1">
                  <h1>Our Products</h1>
                  <p>
                  Our high-quality equipment enhances the overall experience of your conferences, ensuring they run smoothly and professionally.
                  </p>
                </div>
                <div className="our-product-text-1-mobile">
                  <b>Our Products</b>
                  <p>
                    Our high-quality equipment enhances the overall experience of your conferences, ensuring they run smoothly and professionally.
                  </p>
                </div>
              </div>
              <div className="our-product-button-section">
              <a href="/equipment_rental">
                <button className="contact-button">
                  <p>More Equipment</p>
                  <img src="/arrow.svg" alt="" />
                </button>
                </a>
              </div>
            </div>
          </section>

          <div className="our-producte-cart-section">
            <div className="card-container-1">
              
              <ProductCartHome
                imgSrc="/cart1.png"
                altText="Product 1"
                description="We rent FM simultaneous interpretation wireless receivers/earphones. We have one of the largest inventories of wireless receivers/earphones in the country and can ship our simultaneous interpretation equipment nationwide... show more"
                link="/equipment_rental?tag=Multilingual%20Interpretation%20System" // Add link here
              />
              <ProductCartHome
                imgSrc="/cart2.png"
                altText="Product 2"
                description="Our large selection of wired and wireless microphone rentals can be adapted to match the size and setting of your specific event. Whether you're speaking from a podium, or need your hands free while presenting, we can provide the perfect solution to fit your needs... show more"
                link="/equipment_rental?tag=Conference%20Microphone%20System"
              />
            </div>
            <div className="card-container-2">
              <ProductCartHome
                imgSrc="/cart3.png"
                altText="Product 3"
                description="A bright, high-resolution image on a LED screen will attract the attention of your customers and add a new visual dimension to your event regardless of its venue, room size, or format -- be it a conference, seminar, product presentation, live communication with business partners, etc... show more"
                link="/equipment_rental" 
              />
              <ProductCartHome
                imgSrc="/cart4.png"
                altText="Product 4"
                description="With our vast selection of computers, notebooks and tablets we have the latest models from established companies like Thermaltake, Samsung, Dell, MSI, Lenovo and Asus within reach of your personal needs... show more"
                link="/equipment_rental" 

              />
            </div>

            <div className="card-container-1-mobile">
              <ProductCartHome
                imgSrc="/cart1mobile.png"
                altText="Product 1"
                description="We rent FM simultaneous interpretation wireless receivers/earphones. We have one of the largest inventories of wireless receivers/earphones in the country and can ship our simultaneous interpretation equipment nationwide... show more"
                link="/equipment_rental"
              />
              <ProductCartHome
                imgSrc="/cart2mobile.png"
                altText="Product 2"
                description="Our large selection of wired and wireless microphone rentals can be adapted to match the size and setting of your specific event. Whether you're speaking from a podium, or need your hands free while presenting, we can provide the perfect solution to fit your needs... show more"
                link="/equipment_rental"
              />
            </div>
            <div className="card-container-2-mobile">
              <ProductCartHome
                imgSrc="/cart3mobile.png"
                altText="Product 3"
                description="A bright, high-resolution image on a LED screen will attract the attention of your customers and add a new visual dimension to your event regardless of its venue, room size, or format -- be it a conference, seminar, product presentation, live communication with business partners, etc... show more"
                link="/equipment_rental"
              />

            </div>
          </div>
          <div className="contact-baner">
            <div className="contact-baner-text">
              <div className="imgbutton">
                <h1>Let’s work with us</h1>
                <a href="/contact_us">

                <button className="contact-button">
                  <p>Contact</p>
                <img src="/arrow2.png" alt="arrow" />
                </button>
                </a>
              </div>
              <div className="t-b">
              <p>
                &nbsp;&nbsp;With many years of experience in translation/interpretation and equipment rental industry, and numerous successful conferences and satisfied clients, we are confident that we can make your experience with us the very best you will ever have in the field of translation/interpretation and equipment rental services. We are committed to quality translation, interpretation and superior quality simultaneous interpretation and audiovisual equipment and technical support and assistance.
              </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
