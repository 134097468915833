import React from 'react';
import './ClientCart.css';

const ClientCart = ({ imageSrc, descriptionclient }) => {
  return (
    <div className="client-cart-1">
      <img src={imageSrc} alt="client" className="client-image-1" />
      <p className="client-description-1">{descriptionclient}</p>
    </div>
  );
};

export default ClientCart;
