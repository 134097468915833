import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Import the CSS file for styling

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false); // Close the mobile menu
  };

  return (
    <header className={`main-header ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
      <div className="logo-container">
        <h1 className="logo">
          <Link to="/">
            <img src="/Logo.png" alt="Logo" className="logo" />
          </Link>
        </h1>
      </div>
      <nav className="main-navigation">
        <div className="menu-icon" onClick={toggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-list ${mobileMenuOpen ? 'open' : ''}`}>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link to="/">Home</Link>
          </li>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link to="/interpretation_translation">Interpretation/Translation</Link>
          </li>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link to="/equipment_rental">Equipment Rental</Link>
          </li>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link to="/clients_served">Clients Served</Link>
          </li>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link to="/contact_us">Contact Us</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

