import React from 'react';
import './Footer.css';
import logo from '../../assets/Logo.png'; // Regular logo
import logoMobile from '../../assets/Logomobile.png'; // Mobile logo

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer'>
        <div className='footer-logo'>
          <a href="/">

          <img src={window.innerWidth <= 600 ? logoMobile : logo} alt='Logo' className='footer-logo' />
          </a>
          <p>Copyright © "AACS" 2024 All rights reserved.</p>
        </div>

        <div className='footer-our-services'>
          <h4>

            <img src="/Line.svg" alt="line" className="line-icon" /> OUR SERVICES
          </h4>
          <ul>
          <a href="/interpretation_translation">
            <li><img src="/arrow1.svg" alt="line" className="line-icon-s" /> &nbsp;&nbsp;Interpretation/Translation </li>
            </a>
            <a href="/equipment_rental">
           
            <li><img src="/arrow1.svg" alt="line" className="line-icon-s" /> &nbsp;&nbsp;Equipment Rental </li>
            </a>
          </ul>
        </div>

        <div className='footer-contact-us'>
          <h4>
          

            <img src="/Line.svg" alt="line" className="line-icon" /> CONTACT US
           
          </h4>
          <p><img src="/name.svg" alt="line" className="line-icon-s" /> Arthur Aroustamov, MA</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Private Entrepreneur</p>
          <p><img src="/geo.svg" alt="line" className="line-icon-s" /> 74-21 Aram Street</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yerevan 0002, Armenia</p>
          <p><img src="/telephone.svg" alt="line" className="line-icon-s" /> Tel.: (+374 10) 501-516</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mob.: (+374 91) 216-480</p>
          <p><img src="/em.svg" alt="line" className="line-icon-s" /> E-mail: <a href="mailto:aaroustamov@gmail.com">aaroustamov@gmail.com</a></p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
