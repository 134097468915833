// components/ProductCart/ProductCart.jsx

import React from 'react';
import './ProductCart.css';

const ProductCart = ({ imageSrc, description, width }) => {
  return (
    <div style={width ? {width} : {}} className="product-cart">
      <img src={imageSrc} alt="Product" className="product-image" />
      <p className="product-description">{description}</p>
    </div>
  );
};

export default ProductCart;
