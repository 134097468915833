import React from "react";
import "./InterpretationTranslation.css";

const InterpretationTranslation = () => {
  return (
    <div className="Interpretation-section">
      <div className="Interpretation-banner">
        <h1 className="Interpretation-banner-text">
          INTERPRETATION / TRANSLATION
        </h1>
      </div>

      <div className="Interpretation-language-section">
        <h1>Language Interpretation</h1>
        <p>
          &nbsp; &nbsp;Language interpretation has been a part of our lives for
          centuries. Whether for trade, business, government agendas, medical
          reasons, and many others, language interpretation is a natural part of
          how humans in different parts of the globe communicate. However,
          translation and interpretation, while they seem to be similar, have
          important differences in conveying a source language into a target one
          and although they are two sides of the same coin, they do differ.<br /><br /><br />

          &nbsp;&nbsp;Language interpretation can be briefly described as the process of
          verbally conveying a message from a speaker of a source language to a
          target language. In some cases, this can happen in real-time, in
          others, there s a time lag. In addition, the contexts of language
          interpretation may differ, depending on the client s or subject s
          needs. There are numerous benefits to language interpretation and some
          of these include the fact that it can help foster new partnerships, it
          can improve levels of communication with and management of remote
          teams, it helps to connect customers globally, and many others.
          Interpreting services vary, depending on the client s needs. In fact,
          the client can vary on a case-by-case basis. For example, interpreting
          services may be used and required by governmental organizations,
          businesses, at conferences, trade shows, and other events, in a
          medical or educational setting, and so much more. Essentially, and as
          mentioned above, interpreting services are required when a source
          language needs to be conveyed into a target language in real-time or
          as near real-time as possible.
        </p>
      </div>

      <div className="Interpretation-types-section">
        <h1>
          Consecutive Interpreting / Escort Interpreting / Court Interpreting
        </h1>

        <div className="Interpretation-type-consecutive">
          <h1>
            <img src="/arrow1.svg" alt="line" className="line-icon" />
            Consecutive Interpreting
          </h1>
          <p>
            &nbsp;Consecutive interpreting is where the original speaker and the
            interpreter speak in turn. In other words, the original speaker will
            speak for a few seconds, stop talking and wait while the interpreter
            conveys an interpretation in another language, after which the
            original speaker continues. Consecutive interpreting is suitable for
            short presentations or business meetings, but because of the fact
            that everything is said twice (once in each language, alternating in
            time) it doubles the length of any event, which makes it less than
            ideal for major conferences or meetings held in more than one
            foreign language.
          </p>
          <svg
            width="1000"
            height="2"
            viewBox="0 0 1163 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="1.25"
              x2="1163"
              y2="1.25"
              stroke="white"
              stroke-width="1.5"
            />
          </svg>
        </div>

        <div className="Interpretation-type-escort">
          <h1>
            <img src="/arrow1.svg" alt="line" className="line-icon" />
            Escort Interpreting
          </h1>
          <p>
            &nbsp;Escort interpreting is a form of spoken language support for
            tours, visits or travel where individuals require ongoing spoken
            language support. This type of interpretation may be in the
            simultaneous or the consecutive mode.
          </p>
          <svg
            width="1000"
            height="2"
            viewBox="0 0 1163 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="1.25"
              x2="1163"
              y2="1.25"
              stroke="white"
              stroke-width="1.5"
            />
          </svg>
        </div>

        <div className="Interpretation-type-court">
          <h1>
            <img src="/arrow1.svg" alt="line" className="line-icon" />
            Court Interpreting
          </h1>
          <p>
            &nbsp;Court interpreting is when a qualified professional
            communicates the spoken message from one language to another in a
            court hearing or trial. The role of a court interpreter during legal
            processes is of utmost importance. Therefore, you need assistance
            you can rely on aa Conference Services who provide top-class court
            interpreting services. We are experienced and very familiar with
            legal terminology and court proceedings.
          </p>
        </div>
      </div>

      <div className="Interpretation-translation-section">
        <h1>Document Translation</h1>
        <p>
          &nbsp;Language translation is a process involving rewriting source
          language material into a target language, making it appear to have
          been originally written in that language. The process of transposing
          the meaning of a written text from one language (source) to the other
          (target) by producing an equivalent target text that retains the
          elements of meaning, form, and tone.
        </p>
        <p>
          &nbsp;<b>aa Conference Services</b> provides language services in the
          Armenian, English, and Russian languages for various types of
          industries locally and internationally. Our over 20 years of
          experience has given us a strong foundation in the language field. We
          are familiar with the many nuances specific to each language. Our goal
          is to clearly translate the meaning of each sentence. We use
          professional Translation Memory (TM) software well-known in the
          market.
        </p>
        <p>
          &nbsp; aa Conference Services provides consistently superior results,
          devotion to deadlines and responsive customer service. Our clients
          depend on aa Conference Services for total excellence, quality and
          reliability. We guarantee trustworthiness and confidentiality.
        </p>
      </div>

      <div className="Interpretation-certified-section">
        <h1>Notarized / Certified Translation & Immigration Services</h1>
        <p>
          &nbsp;aa Conference Services provides notarized/certified translation
          services. A certified translation is a fully checked, professional,
          quality translation, in relation to which the translator declares that
          he or she is fluent in both the source and the target language(s), and
          certifies under penalty of perjury that the translation is complete
          and accurate to the best of the translator's knowledge and ability. It
          is suitable for official purposes and for submission to governmental
          bodies and other authorities. A notarized translation is a translation
          that is signed and dated by the translator in the presence of a notary
          public.
        </p>
        <p>
          &nbsp; The immigration process to a new country may appear easy, but
          it is far more complex than you may think. Indeed, if you fail to
          complete the forms adequately or yet provide all the documents
          requested by immigration authorities, you will ensure unduly delays in
          your application processing are likely to occur. Moreover, an
          application completed improperly might result in a refusal.
        </p>
        <p>
          &nbsp; At aa Conference Services, we have the expertise to assist you
          with the requirements of immigration. A consultation will give you the
          occasion to ask questions regarding your immigration to an
          English-speaking country, such as the United States, Canada, or
          Australia, and will allow us to gather essential information enabling
          us to better advise you and guide you in the proper direction. aa
          Conference Services offers professional and efficient immigration
          consultancy by providing a no-hassle, one-stop process for potential
          immigrants. With over 20 years of experience in dealing with
          immigration, we aim to provide a reliable and professional service for
          those individuals or families who are serious in their desire to
          migrate.
        </p>
      </div>
    </div>
  );
};

export default InterpretationTranslation;
