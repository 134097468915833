import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const VCS = () => {
  const products = Array.from({ length: 4 }, (_, index) => ({
    imageSrc: `/product6/${index + 1}.png`, // Assuming images are named 1.png, 2.png, ..., 44.png
  }));

  return (
    <div className="cms">
      <div className="product-cart-container-4">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} 
            width="20%"
          />
        ))}
      </div>
      <p>
  &nbsp;<b>aa Conference Services</b> offers professional videoconferencing solutions. Videoconferencing is a great and effective modern form of interactive communication that is similar to a phone conversation with the added ability for the participants to see each other on an LED or projector screen. High-quality videoconferencing solutions allow you to virtually bring together, in real time, many event participants from across countries and continents, thus creating an effect of actual presence. Participants of videoconferences provided by <b> aa Conference Services</b> can monitor each other s facial expressions, gestures, and other non-verbal signals on screen in HD resolution, as well as hear every single word and its intonation, ensured by a high-quality audio signal.
  <br/>
  &nbsp;Our Conference System can be connected to an automatic video tracking system. For video tracking purposes, the video processor or software control can set camera presets for each conference microphone. If the conference microphone is switched on, the video tracking system will automatically find the appropriate preset and focus on the speaker, displaying the view on a large screen.
  <br/>
  &nbsp;Videoconferencing has been shown to have a positive impact on work productivity. Regardless of the location of your lecturer, colleague, or business partner, you can communicate with them face-to-face, as if you were in the same conference room. Videoconferencing offers the opportunity to significantly reduce the costs of organizing international events, enabling communication in a virtual environment and saving both time and travel expenses.
  <br/>
  &nbsp;Before ordering our videoconferencing service, we kindly ask you to prepare information on the number of planned participants, the number of necessary connection points, and the type of connection that is planned to be used. It is also important to know whether, during the videoconference, it is planned to show materials, such as a PowerPoint presentation, from your computer or those of other participants.
</p>

    </div>
  );
};

export default VCS;
