import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const VPS = () => {

  const products = Array.from({ length: 7 }, (_, index) => {
    if (index === 1) {
      return {
        imageSrc: `/product9/${index + 1}.png`,
        width: "18%",
      };
    } else if (index === 3 || index === 4 || index === 5 || index === 6) {
      return {
        imageSrc: `/product9/${index + 1}.png`,
        width: "22%", // Adjust the width as needed
      };
    }
    return {
      imageSrc: `/product9/${index + 1}.png`,
    };
  });
  

  return (
    <div className="mis">
      <div className="product-cart-container-2-vps">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} 
            width={product.width || "36%"} 
          />
        ))}
      </div>
      <p>
      &nbsp; &nbsp;Video projection screens consist of a folding aluminum frame, screen material, and adjustable T-shaped support legs, and are available in a variety of sizes. Projection screens are also available on a tripod.<br /><br />

      &nbsp; &nbsp;All of our multimedia projectors have extremely high resolution, ensuring high quality presentations. We offer both small projectors with wide-angle optics that allow close-up projection of images or video in a small meeting, and powerful 2K resolution projectors for large events that deliver bright, high-quality-and-resolution multimedia projections.<br /><br />

      &nbsp; &nbsp;With our flatscreen rental service, we guarantee you eye-catching events and maximum flexibility. You can rent our monitors in various sizes and find in our display range different devices fromleading manufacturers. Available from 17 inches to 50+ inches.<br /><br />
      </p>
    </div>
  );
};

export default VPS;
