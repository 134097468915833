import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const LVW = () => {

 
  const products = Array.from({ length: 4 }, (_, index) => {
    
    
    if (index === 0) {
      return {
        imageSrc: `/product7/${index + 1}.png`,
        width: "90%" ,
      }
    }
    return  {
      imageSrc: `/product7/${index + 1}.png`, 
    }
  });

  return (
    <div className="mis">
      <div className="product-cart-container-2">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} 
            width={product.width || "27%"}
            
          />
        ))}
      </div>

   

      <p>
        &nbsp; &nbsp; An LED Wall: outstanding image quality, regardless of the room size
        <br /><br />
        Do you have a major event coming up, during which you plan to show presentations, photos, or videos? Are there any lighting problems at your event venue? Are the conditions there not suitable for using a multimedia projector? We have a solution for you – an LED Screen Display!
        <br /><br />
        A bright, high-resolution image on an LED screen will attract the attention of your customers and add a new visual dimension to your event regardless of its venue, room size, or format -- be it a conference, seminar, product presentation, live communication with business partners, etc.
        <br /><br />
        Event organisers often encounter difficulties caused by direct sunlight, strong stage lighting, wide windows, or glass walls that can "fade out" any image on an LCD multimedia projector. The main advantage of LED screens is their brightness, making them suitable for use in very bright environments such as big halls, halls with large windows, shows, and brightly lit stages. An LED screen is the only video image solution that has no visible joints or seams, meaning that your image won't be visually "split" in any way.
        <br /><br />
        LED screens are much easier to install than the classic projector-and-screen setup, saving time and money, especially if the event venue is rented. The screen can be mounted either on the floor with the help of special support stands or hung in the room using special equipment. During the installation of LED screens, no damage is caused to the room, so there’s no need to worry about holes in the ceiling or any marks or scratches.
        <br /><br />
        <b>aa Conference Services</b> will gladly make an offer based on your ideas and the conditions of your event venue, ensuring that your event goes smoothly and sparing you any worries about the technical details. Our team of experienced project managers and technicians will work with you to develop an event plan with the best LED video display solution for you.
      </p>
    </div>
  );
};

export default LVW;
