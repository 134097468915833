import React, { useRef, useState } from 'react'; 
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const userName = formData.get('user_name');
    const userEmail = formData.get('user_email');
    const userPhone = formData.get('user_phone');
    const message = formData.get('message');

    // Simple validation
    if (!userName || !userEmail || !userPhone || !message) {
      setStatusMessage('All fields are required.');
      return;
    }

    const emailData = {
      from_name: userName,        // Sender's name
      reply_to: userEmail,        // Sender's email for the reply-to field
      user_phone: userPhone,      // Sender's phone number
      message: message,           // The message from the form
      to_name: "Harut",           // Receiver's name (you can set it or make it dynamic)
    };

    emailjs.send(
      'service_l9s7azn', 
      'template_m638j3f', 
      emailData, 
      'k8MtgQN7GAZB5BgIr'
    )
    .then((result) => {
      console.log(result.text);
      setStatusMessage('Email sent successfully!');
      form.current.reset(); // Clear form after success
    })
    .catch((error) => {
      console.log(error.text);
      setStatusMessage('Failed to send email. Please try again.');
    });
  };

  return (
    <div className="StyledContactForm">
      <div className='contacttext h1'>
        <h1>Get in <span> Touch </span></h1>
        <p>&nbsp;We welcome inquiries about new projects, old projects, and more. Please call us for an inquiry or a quote. We check our email seven days a week, so that is another efficient way to contact us.</p>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="user_name" placeholder="Name" required />
        <input type="email" name="user_email" placeholder="Email" required />
        <input type="tel" name="user_phone" placeholder="Phone Number" required />
        {/* <textarea name="message" placeholder="How did you find us?" required /> */}
        <input type="message" placeholder="How did you find us?" required />
        <button type="submit">Send</button>
      </form>
      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default ContactForm;
