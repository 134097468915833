import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const CNT = () => {

  const products = Array.from({ length: 6 }, (_, index) => ({
    imageSrc: `/product11/${index + 1}.png`, // Assuming images are named 1.png, 2.png, ..., 44.png
  }));

  return (
    <div className="mis">
      <div className="product-cart-container-2">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} // Unique description for each product
            width="20%"
          />
        ))}
      </div>
      <p>
      &nbsp;&nbsp;     With our vast selection of computers, notebooks and tablets we have the latest models from established companies like Thermaltake, Samsung, Dell,MSI, Lenovo and Asus within reach of your personal needs.
      Offering rentals for high-performance notebooks and computers, it is important that you select the right device representing thefunction-specific you are looking for. 
      <br />  <br />
      &nbsp;&nbsp;    When choosing the right notebook or computer you should consider your personal use case for your event. This is very important in order to meet your personal requirements when looking on performance and memory or RAM issues.<br /><br />

      &nbsp;&nbsp;     In order to make a decision please have a look at our devices showcased here and for a more detailed description of the different use cases we can cover, you can contact us anytime and we will be happy to assist you with your questions and needs.<br />

      </p>
    </div>
  );
};

export default CNT;
