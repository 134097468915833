import React from 'react';
import './ClientsServed.css';
import ClientCart from './ClientCart';

const descriptions = [
  "Government of Armenia",
  "United Nations in Armenia",
   "The World Bank Office in Armenia",
   "International Monetary Fund",
   "Organisation for Economic Co-operation and Development",
   "Delegation of the European Union to Armenia",
   "Council of Europe",
   "EU Water Initiative",
   "World Wildlife Fund Armenia",
   "International Union for Conservation of Nature",
   "United States Agency for International Development Mission to Armenia",
   "German International Cooperation Coordination Office Armenia",
   "German Federal Environment Ministry",
   "Adelphi Consult GmbH Germany",
   "Capacity Building International, Germany",
   "Fichtner GmbH & Co. KG, Germany",
   "The Fridtjof Nansen InstituteNorway",
   "IBF International Consulting Belgium",
   "International Committee of the Red Cross",
   "Golden Apricot Yerevan International Film Festival ",
   "Habitat for Humanity Armenia",
   "Hrant Dink Foundation",
   "Internet Society NGO",
   "Jinishian Memorial Foundation",
   "Konrad Adenauer Foundation, Armenian Branch",
   "Green Lane NGO",
   "Westminster Foundation for Democracy, United Kingdom",
   "ibis Yerevan Center",
   "JASUTA DMC & Tour Оperator",
   "Ani Grand Hotel",
   "The Regional Environment Centre for the Caucasus",
   "Armenian Red Cross Society",
   "Teach For Armenia Educational Foundation",
   "Armenian Lawyers’ Association NGO",
   "OxYGen Foundation for Protection of Youth and Women Rights",
   "National Erasmus+ Office in Armenia",
   "Yerevan State University",
   "American University of Armenia",
   "Eurasia Partnership Foundation - Armenia",
   "Save the Children Armenia",
   "World Vision Armenia",
   "Arabkir Medical Centre",
   "Action Santé Arménie-France ",
   "AraratBank Armenia",
   "Mary Kay Armenia",
   "The Civilitas Foundation Armenia",
   "Pernod Ricard RoussRussia",
   "Yerevan Brandy Company",
   "Language Bank, Inc.United States",
   "The LanguageWorks, Inc. United States",
   "TransPerfect Translations International, Inc. United States",
   "Applied Language Solutions, Ltd., United Kingdom",
   "Lingo24, Ltd., United Kingdom",
   "interlanguage s.r.l., Italy",
   
];

const clients = Array.from({ length: 54 }, (_, index) => ({
  imageSrc: `/product13/${index + 1}.png`,
  descriptionclient: descriptions[index % descriptions.length] || "Description not available", // Ensures a description or fallback text
}));

const ClientsServed = () => {
  return (
    <div className='clientserved-section-er'>
      {/* Banner Section */}
      <div className="clientserved-banner-er">
        <h1 className="clientserved-banner-text-er">CLIENTS SERVED</h1>
      </div>
      
      {/* Description Section */}
      <div className="clientserved-description-er">
        <h1 className="clientserved-heading-er">
          &nbsp; Our esteemed clients span various industries and sectors, including international organizations, government agencies, non-profits, and private corporations.
        </h1>
        <p className="clientserved-paragraph-er">
          We are committed to delivering exceptional service tailored to the unique needs of each client, ensuring their events run smoothly and effectively.
        </p>
      </div>

      {/* Clients Cart Section */}
      <div className="client-cart-container">
        {clients.map((client, index) => (
          <ClientCart
            key={index}  // Key should be unique for each mapped item
            imageSrc={client.imageSrc}  // Image source
            descriptionclient={client.descriptionclient}  // Description of the client
          />
        ))}
      </div>
    </div>
  );
}

export default ClientsServed;
