import React from "react";
import ProductCart from "../../ProductsCard/ProductCart";

const MultilingualInterpretationSystem = () => {

  const products = Array.from({ length: 6 }, (_, index) => ({
    imageSrc: `/product2/${index + 1}.png`, // Assuming images are named 1.png, 2.png, ..., 44.png
  }));

  return (
    <div className="mis">
      <div className="product-cart-container-2">
        {products.map((product) => (
          <ProductCart
            key={product.imageSrc}
            imageSrc={product.imageSrc} // Image source
            description={product.description} 
            width='23%'
          />
        ))}
      </div>
      <p>
      &nbsp;As seen on TV, at the United Nations, the European Parliament and at the EU, interpretation systems link a speaker using one language to listeners using one or more other languages via interpreters sitting in booths.<br/>
  <b>aa Conference Services </b> offers up-to-date equipment for simultaneous translation. Our simultaneous translation equipment is the best in Armenia by such parameters as sound fidelity, 100% interference resistance, and ease of use.<br/>
  We rent FM simultaneous interpretation wireless receivers/earphones. We have one of the largest inventories of wireless receivers/earphones in the country and can ship our simultaneous interpretation equipment nationwide.<br/>
  &nbsp;&nbsp;Our 8-channel wireless receivers are state-of-the-art, user-friendly, and have crystal clear sound. There is a volume control for your comfort and a channel selector for easy language channel selection.<br/>
  &nbsp;&nbsp;Our multilingual interpretation system is comprised of:<br/>
  &nbsp;&nbsp;&nbsp;&nbsp;- Interpreter consoles, which allow the interpreters to control their receiving and transmitted audio, dedicate different languages to separate language channels, as well as individual headsets permitting them to listen through and concentrate exclusively on the speech they are interpreting.<br/>
  &nbsp;&nbsp;&nbsp;&nbsp;- Wireless transmitters that allow our interpreters to perform their job comfortably and also provide a clear transmission of the interpretation to the receivers for the success of your conference.
</p>

    </div>
  );
};

export default MultilingualInterpretationSystem;
